import {
  Box,
  Heading,
  Text,
  useColorModeValue as mode,
  WrapItem,
} from "@chakra-ui/react";
import { BunSet } from "database/model/bunset";
import { formatTimestamp } from "framework/libs/date";
import _ from "lodash";
import { Link } from "react-router-dom";

interface BunSetCardProps {
  bunset: BunSet;
}

const BunSetCard = ({ bunset }: BunSetCardProps) => {
  return (
    <WrapItem>
      <Box
        width="300px"
        mx="auto"
        p={6}
        bg={mode("white", "gray.700")}
        px={{ base: "6", md: "8" }}
        shadow={{ md: "base" }}
        rounded={{ md: "xl" }}
      >
        <Box>
          <Link to={`/bunset/${bunset.id}`}>
            <Heading
              fontWeight="extrabold"
              fontSize="lg"
              title={bunset.name}
              isTruncated
              color="purple.600"
            >
              {bunset.name}
            </Heading>
          </Link>
          <Text fontSize="xs" mt={2} h={70} overflow="clip">
            {_.truncate(bunset.description, { length: 120 })}
          </Text>
          <Text fontSize="x-small" mt={2}>
            {formatTimestamp(bunset.modified?.toDate())}
          </Text>
        </Box>
      </Box>
    </WrapItem>
  );
};

export default BunSetCard;
