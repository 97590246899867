import {
  Avatar,
  AvatarBadge,
  Box,
  Divider,
  Flex,
  HStack,
  Menu,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue as mode,
  useMenuButton,
  UseMenuButtonProps,
} from "@chakra-ui/react";
import firebase from "firebase/app";
import * as React from "react";
import { FiLogOut } from "react-icons/fi";

interface Props {
  user: firebase.User;
  signOut: Function;
}

const UserAvatar = ({ user }: { user: firebase.User }) => {
  return (
    <Avatar name={user?.displayName || ""} src={user?.photoURL || ""} size="sm">
      <AvatarBadge boxSize="1em" bg="green.500" />
    </Avatar>
  );
};

const ProfileMenuButton = (
  props: UseMenuButtonProps & { user: firebase.User }
) => {
  const buttonProps = useMenuButton(props);

  return (
    <Flex
      {...buttonProps}
      as="button"
      flexShrink={0}
      rounded="full"
      outline="0"
      _focus={{ shadow: "outline" }}
    >
      <Box srOnly>Open user menu</Box>
      <UserAvatar user={props.user} />
    </Flex>
  );
};

export const UserProfile: React.FC<Props> = (props) => {
  const { signOut, user } = props;

  return (
    user && (
      <Menu>
        <ProfileMenuButton user={user} />
        <MenuList
          rounded="md"
          shadow="lg"
          py="1"
          color={mode("gray.600", "inherit")}
          fontSize="sm"
          zIndex={2}
        >
          <HStack px="3" py="4">
            <UserAvatar user={props.user} />
            <Box lineHeight="1">
              <Text fontWeight="semibold">{user.displayName}</Text>
              <Text mt="1" fontSize="xs" color="gray.500">
                {user.email}
              </Text>
            </Box>
          </HStack>
          <Divider />
          <MenuItem
            fontWeight="medium"
            icon={<FiLogOut />}
            onClick={() => signOut()}
            color={mode("red.500", "red.300")}
          >
            Sign out
          </MenuItem>
        </MenuList>
      </Menu>
    )
  );
};
