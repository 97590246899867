import { Token } from "framework/japanese/sentence";
import _ from "lodash";

import { posKey } from "../constants";

import General from "./General";

export const getMarker = (token: Token) => {
  const pos = _.get(posKey, _.get(token.pos, "0"));
  switch (pos) {
    case "noun":
    case "particle":
    case "verb":
    case "adjective":
    case "conjunction":
      return General;

    default:
      return General;
  }
};
