import splash from "features/splash";
import { Route } from "react-router-dom";
import { AuthCheck } from "reactfire";

const { App: Splash } = splash.components;

const ProtectedRoute = ({ component: Component, path, ...rest }: any) => {
  const render = (props: any) => {
    return (
      <AuthCheck fallback={<Splash></Splash>}>
        <Component {...props} />
      </AuthCheck>
    );
  };

  return <Route path={path} render={render} {...rest} />;
};

export default ProtectedRoute;
