import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Img,
  Stack,
  Text,
  useColorModeValue as mode,
  Wrap,
} from "@chakra-ui/react";
import Loading from "components/loading/Loading";
import { RouteProps } from "framework/routing/types";
import _ from "lodash";
import * as React from "react";
import { FaPlusCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useFirestore, useFirestoreCollectionData, useUser } from "reactfire";
import BunSetCard from "./BunSetCard";

interface BunSetsProps extends RouteProps {}

const BunSets = (props: BunSetsProps) => {
  const { data: user } = useUser();
  const fireStore = useFirestore();

  const bunSetsRef = fireStore
    .collection("bunsets")
    .where(`roles.${user.uid}`, "==", "owner");

  const { status, data: bunSets } = useFirestoreCollectionData<any>(
    bunSetsRef,
    {
      idField: "id",
    }
  );

  return (
    <Box>
      <Box as="section" bgGradient="linear(to-t, gray.50, purple.50)" py="10">
        <Box
          maxW={{ base: "xl", md: "8xl" }}
          mx="auto"
          px={{ base: "6", md: "8" }}
        >
          <Stack
            spacing={{ base: "4", lg: "20" }}
            direction={{ base: "column", lg: "row" }}
          >
            <Center flex="1" shadow="lg" minH="10rem" maxW={{ lg: "xl" }}>
              <Img
                objectFit="cover"
                w="full"
                h="full"
                htmlWidth="576px"
                htmlHeight="420px"
                src="https://source.unsplash.com/ywqa9IZB-dU/1600x900"
                alt="Japanese"
              />
            </Center>
            <Box w={{ lg: "xl" }}>
              <Heading
                size="xl"
                fontWeight="extrabold"
                letterSpacing="tight"
                lineHeight="normal"
              >
                My Sentence Decks
              </Heading>
              <Text fontSize="md" mt="4" color={mode("gray.600", "gray.400")}>
                Create sentence decks to study grammar, particles, kanji or
                really just to collect and memorize your favorite phrases ❤
              </Text>
              <Link to="/bunsets/add">
                <Button
                  className="group"
                  mt="8"
                  colorScheme="purple"
                  size="lg"
                  px="8"
                  fontWeight="bold"
                  h="14"
                  iconSpacing="3"
                  variant="outline"
                  leftIcon={
                    <Box
                      as={FaPlusCircle}
                      fontSize="sm"
                      mb={-1}
                      transition="transform 0.2s"
                      _groupHover={{ transform: "scale(1.3)" }}
                    />
                  }
                >
                  Sentence Deck
                </Button>
              </Link>
            </Box>
          </Stack>
          <Divider mt={10} opacity={1} />

          {status === "loading" && (
            <Loading width="100%" mt="200">
              <Text fontWeight="semibold" p="5" textAlign="center">
                Loading your decks...
              </Text>
            </Loading>
          )}

          {status === "success" && (
            <Flex p="8" flex="1" overflowY="auto" minH="0">
              <Wrap spacing="20px">
                {_.chain(bunSets)
                  .orderBy(["modified"], ["desc"])
                  .map((x: any, idx) => <BunSetCard key={idx} bunset={x} />)
                  .value()}
              </Wrap>
            </Flex>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BunSets;
