import firebase from "firebase/app";
import "firebase/functions";

export interface Token {
  base_form: string;
  class: string;
  start: number;
  end: number;
  features: string[];
  pos: string[];
  reading: string;
  surface: string;
  pronunciation: string;
}

interface TokenizeData {
  status: boolean;
  tokens: Token[];
}

interface TokenizeResult {
  data: TokenizeData;
  sentence: string;
}

export const tokenize = async (sentence: string) => {
  const tokenizeSentence = firebase
    .functions()
    .httpsCallable("tokenizeSentence");
  const result = await tokenizeSentence({
    sentence,
  });

  return result.data as TokenizeResult;
};

interface Translation {
  detected_source_language: string;
  text: string;
}
interface TranslationData {
  translations: Translation[];
}
interface TranslationResult {
  data: TranslationData;
  sentence: string;
}

export const translate = async (sentence: string) => {
  const translateSentence = firebase
    .functions()
    .httpsCallable("translateSentence");
  const result = await translateSentence({
    sentence,
  });

  return result.data as TranslationResult;
};
