import { BunRecording } from "database/model/bun";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { Token } from "framework/japanese/sentence";
import _ from "lodash";

export interface AddBunProps {
  sentence: string;
  notes: string;
  tokens: Token[];
  translation: string;
}

export const addBun = async (
  bunSetId: string,
  data: AddBunProps,
  fireStore: firebase.firestore.Firestore
) => {
  const ref = fireStore
    .collection("bunsets")
    .doc(bunSetId)
    .collection("buns")
    .doc();
  await ref.set({
    id: ref.id,
    bunSetId,
    ...data,
    created: firebase.firestore.FieldValue.serverTimestamp(),
    modified: firebase.firestore.FieldValue.serverTimestamp(),
  });

  return ref.id;
};

export const updateBun = async (
  bunId: string,
  bunSetId: string,
  data: {
    sentence?: string;
    notes?: string;
    tokens?: Token[];
    recording?: BunRecording;
  },
  fireStore: firebase.firestore.Firestore
) => {
  const ref = fireStore
    .collection("bunsets")
    .doc(bunSetId)
    .collection("buns")
    .doc(bunId);

  await ref.update(
    _.assign(data, {
      modified: firebase.firestore.FieldValue.serverTimestamp(),
    })
  );
};

export const deleteBun = async (
  bunId: string,
  bunSetId: string,
  fireStore: firebase.firestore.Firestore
) => {
  const ref = fireStore
    .collection("bunsets")
    .doc(bunSetId)
    .collection("buns")
    .doc(bunId);

  await ref.delete();
};

/**
 * Storage actions
 */

export const uploadBunRecording = async (
  bunId: string,
  recording: Blob,
  fireStorage: firebase.storage.Storage
) => {
  try {
    const ref = fireStorage.ref(`audio/bun/${bunId}`);
    return await ref.put(recording);
  } catch (error) {
    console.error(error);
  }
};
