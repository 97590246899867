import _ from "lodash";
import { isKanji } from "wanakana";

export const extractKanji = (data: string) => {
  return _.chain(data).split("").filter(isKanji).uniq().value();
};

export const hasKanji = (data: string) => {
  return !_.isEmpty(extractKanji(data));
};
