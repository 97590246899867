import KanjiGrid from "components/kanji/KanjiGrid";
import { Bun } from "database/model/bun";
import { extractKanji } from "framework/japanese/kanji";
import _ from "lodash";

interface BunSetKanjiProps {
  buns: Bun[];
}

const BunSetKanji = ({ buns }: BunSetKanjiProps) => {
  const kanjis = _.chain(buns)
    .map("sentence")
    .flatMap((x) => extractKanji(x))
    .uniq()
    .value();

  return <KanjiGrid kanjis={kanjis} />;
};

export default BunSetKanji;
