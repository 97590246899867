import {
  Box,
  Flex,
  Grid,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import _ from "lodash";
import * as React from "react";
import { joyoKanjiAtom } from "state/jotai/data/joyo";
import { rtkKanjiAtom } from "state/jotai/data/rtk";
import { wkKanjiAtom } from "state/jotai/data/wk";
import { isKana, toHiragana, toKatakana } from "wanakana";

interface KanjiCardProps {
  kanji: string;
  data: any;
}

const KanjiCard = ({ data, kanji }: KanjiCardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const keyword = _.chain(data)
    .get(["rtk", "keyword"], "")
    .capitalize()
    .value();
  const isDisabled = _.isEmpty(keyword);

  const jlpt = _.get(data, ["joyo", "jlpt"], 0);
  const bg = {
    5: "purple.50",
    4: "purple.100",
    3: "orange.100",
    2: "red.100",
    1: "blue.100",
    0: "twitter.200",
  };

  const bgFinal = _.get(bg, jlpt);
  const ons = _.chain(data)
    .get(["joyo", "wk_on"])
    .split(",")
    .map((x) => toKatakana(x))
    .map((x) => _.chain(x).filter(isKana).join("").value())
    .join(", ")
    .value();

  const kuns = _.chain(data)
    .get(["joyo", "wk_kun"])
    .split(",")
    .map((x) => toHiragana(x))
    .map((x) => _.chain(x).filter(isKana).join("").value())
    .join(", ")
    .value();

  const radicals = _.chain(data)
    .get(["rtk", "components"])
    .split(";")
    .map((x) => _.trim(x))
    .map((x) => _.capitalize(x))
    .join(", ")
    .value();

  const label = isDisabled ? (
    <></>
  ) : (
    <Stat>
      <StatLabel>{`JLPT ${jlpt}`}</StatLabel>
      <StatNumber>{keyword}</StatNumber>
      <StatHelpText>
        <Text fontSize="xs" mb={2}>
          {radicals}
        </Text>
        <Text
          color="white"
          fontSize="md"
          fontFamily="heisei-maru-gothic-std, sans-serif"
        >
          {ons}
        </Text>
        <Text
          color="white"
          fontSize="md"
          fontFamily="heisei-maru-gothic-std, sans-serif"
        >
          {kuns}
        </Text>
      </StatHelpText>
    </Stat>
  );

  const kanjiRRTKLink = `https://hochanh.github.io/rtk/${kanji}/index.html`;

  return (
    <Box
      w="100%"
      p={2}
      bg={bgFinal}
      textAlign="center"
      color="blackAlpha.800"
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
    >
      <Tooltip
        hasArrow
        isOpen={isOpen && !isDisabled}
        isDisabled={isDisabled}
        label={label}
        placement="top-start"
        arrowSize={10}
        p={2}
        colorScheme="purple"
        bg="purple.600"
        minWidth={200}
      >
        <Text
          as="button"
          fontSize="3xl"
          fontFamily="heisei-maru-gothic-std, sans-serif"
        >
          <a href={kanjiRRTKLink} target="__blank">
            {kanji}
          </a>
        </Text>
      </Tooltip>
    </Box>
  );
};

interface KanjiGridProps {
  kanjis: string[];
}

const KanjiGrid = ({ kanjis }: KanjiGridProps) => {
  const [joyoKanji] = useAtom(joyoKanjiAtom);
  const [rtkKanji] = useAtom(rtkKanjiAtom);
  const [wkkanji] = useAtom(wkKanjiAtom);

  return (
    <Flex flex="1" overflowY="auto" minH="0">
      <Grid
        w="100%"
        templateColumns="repeat(auto-fit, minmax(60px, 60px))"
        gap={1}
      >
        {kanjis.map((x: any, idx: any) => {
          const data = _.merge(
            { joyo: _.get(joyoKanji, x) },
            { rtk: _.get(rtkKanji, x) },
            { wk: _.get(wkkanji, x) }
          );

          return <KanjiCard key={idx} kanji={x} data={data} />;
        })}
      </Grid>
    </Flex>
  );
};

export default KanjiGrid;
