import ErrorBoundary from "components/error/ErrorBoundary";
import ProtectedRoute from "framework/routing/protected";
import React from "react";
import { Redirect, Switch } from "react-router-dom";

import AddBun from "./AddBun";
import Bun from "./Bun";
import BunSet from "./BunSet";
import EditBunSet from "./EditBunSet";

import "./styles.scss";

const App = () => {
  return (
    <ErrorBoundary>
      <Switch>
        <ProtectedRoute
          exact
          path="/bunset/:id/sentences/add"
          component={AddBun}
        />
        <ProtectedRoute exact path="/bunset/:id/edit" component={EditBunSet} />
        <ProtectedRoute path="/bunset/:id/sentences/:bunId" component={Bun} />
        <ProtectedRoute path="/bunset/:id/:tab" component={BunSet} />
        <ProtectedRoute path="/bunset/:id" component={BunSet} />
        <ProtectedRoute render={() => <Redirect to="/dashboard" />} />
      </Switch>
    </ErrorBoundary>
  );
};

export default App;
