import { Box, HStack, IconButton } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FaPause, FaPlay, FaStop } from "react-icons/fa";
import WaveSurfer from "wavesurfer.js";

interface AudioPlayerProps {
  url: string;
}

const AudioPlayer = (props: AudioPlayerProps) => {
  const surferRef = useRef<any>();
  const [surfer, setSurfer] = useState<any>();
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      barWidth: 2,
      cursorWidth: 1,
      container: surferRef.current,
      backend: "WebAudio",
      height: 40,
      progressColor: "rgba(107, 70, 193, 0.8)",
      responsive: true,
      waveColor: "#E6E6E6",
      cursorColor: "transparent",
    });

    // Load the audio
    waveSurfer.load(props.url);
    waveSurfer.on("ready", function () {
      setSurfer(waveSurfer);
    });

    waveSurfer.on("play", function () {
      setPlaying(true);
    });

    waveSurfer.on("pause", function () {
      setPlaying(false);
    });

    return () => {
      waveSurfer.destroy();
    };
  }, [props.url]);

  return (
    <HStack flex={1}>
      <IconButton
        variant="outline"
        colorScheme="purple"
        aria-label="Stop Audio"
        icon={<FaStop />}
        onClick={() => surfer.stop()}
      />
      {!playing && (
        <IconButton
          variant="outline"
          colorScheme="purple"
          aria-label="Play Audio"
          icon={<FaPlay />}
          onClick={() => surfer.play()}
        />
      )}
      {playing && (
        <IconButton
          variant="outline"
          colorScheme="purple"
          aria-label="Stop Audio"
          icon={<FaPause />}
          onClick={() => surfer.pause()}
        />
      )}

      <Box id="waveform" ref={surferRef} flex={1}>
        <audio id="track" />
      </Box>
    </HStack>
  );
};

export default AudioPlayer;
