/**
 * Date time helpers
 */

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";

// Enable plugins
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

export function formatTimestamp(timestamp: Date) {
  return dayjs(timestamp).fromNow();
}
