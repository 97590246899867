import { Link, LinkProps, useColorModeValue as mode } from "@chakra-ui/react";
import * as React from "react";
import { Link as ReactRouterLink } from "react-router-dom";

type NavLinkProps = LinkProps & { isActive?: boolean; to: string };

export const NavLink = (props: NavLinkProps) => {
  const { isActive, to, ...rest } = props;

  return (
    <Link
      as={ReactRouterLink}
      to={to}
      display="block"
      py={2}
      px={3}
      borderRadius="md"
      transition="all 0.3s"
      fontWeight="medium"
      lineHeight="1.25rem"
      aria-current={isActive ? "page" : undefined}
      _hover={{ bg: mode("gray.100", "gray.700") }}
      _activeLink={{
        bg: mode("purple.600", "purple.300"),
        color: mode("white", "gray.900"),
      }}
      {...rest}
    />
  );
};
