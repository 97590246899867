import { Text, TextProps } from "@chakra-ui/react";
import { Token } from "framework/japanese/sentence";
import { getFurigana } from "./helpers";

interface RenderProps extends TextProps {
  color?: string;
  token: Token;
  underline: boolean;
}

interface WithRubyProps extends RenderProps {}

export const WithRuby = ({
  token,
  color,
  underline,
  ...textProps
}: WithRubyProps) => {
  const furigana = getFurigana(token);

  return (
    <Text
      as="span"
      textAlign="center"
      color={color ? color : "gray.500"}
      textDecoration={underline ? "underline" : ""}
      dangerouslySetInnerHTML={{ __html: furigana.ReadingHtml }}
      {...textProps}
    ></Text>
  );
};

interface WithoutRubyProps extends RenderProps {}

export const WithoutRuby = ({ token, color, underline }: WithoutRubyProps) => {
  return (
    <Text
      as="span"
      color={color ? color : "gray.500"}
      textDecoration={underline ? "underline" : ""}
    >
      {token.surface}
    </Text>
  );
};
