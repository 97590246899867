import { Box } from "@chakra-ui/layout";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import RecordRTC from "recordrtc";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";
import Record from "videojs-record/dist/videojs.record.js";
import "videojs-wavesurfer/dist/css/videojs.wavesurfer.css";
import Wavesurfer from "videojs-wavesurfer/dist/videojs.wavesurfer.js";
import MicrophonePlugin from "wavesurfer.js/dist/plugin/wavesurfer.microphone.js";
import "webrtc-adapter";

import "./styles.scss";

const videoJsOptions = {
  controls: true,
  bigPlayButton: false,

  controlBar: {
    fullscreenToggle: false,
    volumePanel: true,
  },

  height: 200,
  fill: true,
  inactivityTimeout: 0,

  plugins: {
    wavesurfer: {
      backend: "WebAudio",
      waveColor: "rgba(107, 70, 200, 0.6)",
      progressColor: "rgba(107, 70, 200, 0.8)",
      debug: false,
      cursorWidth: 1,
      barWidth: 2,
      msDisplayMax: 20,
      hideScrollbar: true,
      displayMilliseconds: true,
      recordIndicator: true,
      plugins: [
        MicrophonePlugin.create({
          bufferSize: 4096,
          numberOfInputChannels: 1,
          numberOfOutputChannels: 1,
          constraints: {
            video: false,
            audio: true,
          },
        }),
      ],
    },
    record: {
      audio: true,
      video: false,
      maxLength: 20,
      debug: false,
    },
  },
};

interface AudioRecorderProps {
  onRecord: (data: Blob) => void;
}

const AudioRecorder = ({ onRecord }: AudioRecorderProps) => {
  const audioRef = useRef<any>();
  const [audioPlayer, setAudioPlayer] = useState<any>(undefined);

  useEffect(() => {
    return () => {
      if (!_.isUndefined(audioPlayer)) {
        audioPlayer.dispose();
      }
    };
  }, [audioPlayer]);

  useEffect(() => {
    if (!audioPlayer) {
      const player: any = videojs(audioRef.current, videoJsOptions, () => {
        // If these lines are removed TS will shake the tree
        if (_.isUndefined(Wavesurfer) && _.isUndefined(Record)) {
          console.debug("Loading Audio");
        }

        const version_info = `Using video.js ${
          videojs.VERSION
        } with videojs-record ${videojs.getPluginVersion(
          "record"
        )} and recordrtc ${RecordRTC.version}`;

        videojs.log(version_info);
      });

      setAudioPlayer(player);

      player.on("deviceReady", () => {
        player.record().start();
      });

      player.on("finishRecord", () => {
        onRecord(player.recordedData);
      });
    }
  }, [onRecord, audioPlayer]);

  return (
    <Box height={140}>
      <Box data-vjs-player>
        <video
          id="audio"
          style={{
            backgroundColor: "transparent",
          }}
          ref={audioRef}
          className="video-js vjs-custom-skin"
          playsInline
        ></video>
      </Box>
    </Box>
  );
};

export default AudioRecorder;
