import { loadKanji, loadVocab } from "framework/data/loader";
import { useAtom } from "jotai";
import _ from "lodash";
import { useEffect } from "react";
import { c6kVocabAtom } from "state/jotai/data/c6k";
import { jlptVocabAtom } from "state/jotai/data/jlpt";
import { joyoKanjiAtom } from "state/jotai/data/joyo";
import { kicVocabAtom } from "state/jotai/data/kic";
import { rtkKanjiAtom } from "state/jotai/data/rtk";
import { tryVocabAtom } from "state/jotai/data/try";
import { wkKanjiAtom } from "state/jotai/data/wk";

const Data = (props: {}) => {
  const [joyoKanji, setJoyoKanji] = useAtom(joyoKanjiAtom);
  const [wkKanji, setWkKanji] = useAtom(wkKanjiAtom);
  const [rtkKanji, setRtkKanji] = useAtom(rtkKanjiAtom);

  const [kicVocab, setKicVocab] = useAtom(kicVocabAtom);
  const [tryVocab, setTryVocab] = useAtom(tryVocabAtom);
  const [c6kVocab, setC6kVocab] = useAtom(c6kVocabAtom);

  const [jlptVocab, setJlptVocab] = useAtom(jlptVocabAtom);

  useEffect(() => {
    (async () => {
      if (_.isEmpty(joyoKanji)) {
        console.log("Downloading Joyo Kanji Data...");
        const data = await loadKanji("/data/joyo.csv");
        setJoyoKanji(data);
      }
    })();
  }, [joyoKanji, setJoyoKanji]);

  useEffect(() => {
    (async () => {
      if (_.isEmpty(wkKanji)) {
        console.log("Downloading WK Kanji Data...");
        const data = await loadKanji("/data/wk.csv");
        setWkKanji(data);
      }
    })();
  }, [wkKanji, setWkKanji]);

  useEffect(() => {
    (async () => {
      if (_.isEmpty(rtkKanji)) {
        console.log("Downloading RTK Kanji Data...");
        const data = await loadKanji("/data/rtk.csv");
        setRtkKanji(data);
      }
    })();
  }, [rtkKanji, setRtkKanji]);

  useEffect(() => {
    (async () => {
      if (_.isEmpty(kicVocab)) {
        console.log("Downloading KIC Vocabulary Data...");
        const data = await loadVocab("/data/kic.csv", "expression");
        setKicVocab(data);
      }
    })();
  }, [kicVocab, setKicVocab]);

  useEffect(() => {
    (async () => {
      if (_.isEmpty(tryVocab)) {
        console.log("Downloading TRY Vocabulary Data...");
        const data = await loadVocab("/data/try.csv", "expression");
        setTryVocab(data);
      }
    })();
  }, [tryVocab, setTryVocab]);

  useEffect(() => {
    (async () => {
      if (_.isEmpty(c6kVocab)) {
        console.log("Downloading C6K Vocabulary Data...");
        const data = await loadVocab("/data/c6k.csv", "expression");
        setC6kVocab(data);
      }
    })();
  }, [c6kVocab, setC6kVocab]);

  useEffect(() => {
    (async () => {
      if (_.isEmpty(jlptVocab)) {
        console.log("Downloading JLPT Vocabulary Data...");
        const data = await loadVocab("/data/jlpt.csv", "expression");
        setJlptVocab(data);
      }
    })();
  }, [jlptVocab, setJlptVocab]);

  return null;
};

export default Data;
