// React Error Boundary
// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/error_boundaries/

import { Box, Text } from "@chakra-ui/react";
import { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  firebaseError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    firebaseError: false,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.

    let firebaseError = false;
    if (error.message === "Missing or insufficient permissions.") {
      console.error("Clearing the cache ...");
      firebaseError = true;
    }

    return { hasError: true, firebaseError };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    if (error.message === "Missing or insufficient permissions.") {
      console.error("Clearing the cache ...");
    }
  }

  clearFirestoreCache = () => {
    const map = (globalThis as any)["_reactFirePreloadedObservables"];
    Array.from(map.keys()).forEach(
      (key: any) => key.includes("firestore") && map.delete(key)
    );
  };

  public render() {
    if (this.state.hasError) {
      if (this.state.firebaseError) {
        this.clearFirestoreCache();
        return this.props.children;
      } else {
        return (
          <Box>
            <Text>
              Error during component load, please reload this page! 🙇‍♂️
            </Text>
          </Box>
        );
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
