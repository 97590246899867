export const posColor = {
  名詞: "purple.600",
  助詞: "red.600",
  動詞: "twitter.600",
  形容詞: "green.600",
  接続詞: "facebook.600",
};

export const posKey = {
  名詞: "noun",
  助詞: "particle",
  動詞: "verb",
  形容詞: "adjective",
  接続詞: "conjunction",
};
