import { Box, BoxProps, useDisclosure } from "@chakra-ui/react";
import { Token } from "framework/japanese/sentence";
import { useState } from "react";
import _ from "lodash";

import { getMarker } from "./pos/marker";

import "./styles.scss";
import HookForm from "./forms/HookForm";

interface SentenceMarkupProps extends BoxProps {
  sentence: string;
  tokens: Token[];
  editable?: boolean;
  hideFurigana?: boolean;
  onSave?: (tokens: Token[]) => void;
}

const SentenceMarkup = ({
  editable,
  sentence,
  tokens,
  onSave,
  hideFurigana,
  ...boxProps
}: SentenceMarkupProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [token, setToken] = useState<Token>();

  const onData = (token: Token) => {
    const tokenIdx = _.chain(tokens)
      .findIndex(_.pick(token, ["start", "end"]))
      .value();

    const updatedTokens = _.chain(tokens)
      .cloneDeep()
      .set([tokenIdx, "reading"], token.reading)
      .value();

    if (editable && onSave) {
      onSave(updatedTokens);
    }
  };

  const selectToken = (idx: number) => {
    setToken(_.get(tokens, idx));
    onOpen();
  };

  return (
    <>
      {editable && token && (
        <HookForm
          isOpen={isOpen}
          onClose={onClose}
          onData={onData}
          data={token}
        />
      )}
      <Box className="sentence-markup" {...boxProps}>
        {tokens?.map((token, idx) => {
          const Marker = getMarker(token);
          return (
            <Marker
              key={idx}
              idx={idx}
              token={token}
              editable={editable}
              hideFurigana={hideFurigana}
              selectToken={selectToken}
            />
          );
        })}
      </Box>
    </>
  );
};

export default SentenceMarkup;
