import { Box, Flex, Grid, Tag, Text } from "@chakra-ui/react";
import { Bun } from "database/model/bun";
import { extractKanji } from "framework/japanese/kanji";
import { Furigana } from "gem-furigana";
import { useAtom } from "jotai";
import _ from "lodash";
import { c6kVocabAtom } from "state/jotai/data/c6k";
import { jlptVocabAtom } from "state/jotai/data/jlpt";
import { kicVocabAtom } from "state/jotai/data/kic";
import { rtkKanjiAtom } from "state/jotai/data/rtk";
import { tryVocabAtom } from "state/jotai/data/try";
import { isKana } from "wanakana";

const pos = {
  名詞: "noun",
  動詞: "verb",
  形容詞: "adjective",
};

const extractWords = (bun: Bun): string[] => {
  return _.chain(bun.tokens)
    .filter(
      (x) =>
        _.keys(pos).includes(_.get(x, "pos.0")) &&
        !["代名詞", "非自立", "接尾"].includes(_.get(x, "pos.1"))
    )
    .map("base_form")
    .filter((x) => !isKana(x))
    .value();
};

interface WordCardProps {
  expression: string;
  reading: string;
  meanings: string;
  jlpt: number;
  notes?: string;
}

const WordCard = ({ expression, reading, meanings, notes }: WordCardProps) => {
  const [rtkKanji] = useAtom(rtkKanjiAtom);
  const [jlptVocab] = useAtom(jlptVocabAtom);
  const jlptLevel = _.chain(_.get(jlptVocab, expression, [{ jlpt: 0 }]))
    .map("jlpt")
    .max()
    .value();

  const bg = {
    5: "purple.50",
    4: "purple.100",
    3: "orange.100",
    2: "red.100",
    1: "blue.100",
    0: "twitter.100",
  };
  const bgFinal = _.get(bg, jlptLevel);

  const furigana = new Furigana(reading);
  const kanjis = _.chain(expression)
    .flatMap(extractKanji)
    .map((x) => _.get(rtkKanji, x))
    .compact()
    .map("keyword")
    .map(_.capitalize)
    .value();

  const JishoLink = `https://jisho.org/search/${expression}`;

  return (
    <Box
      p={2}
      w="100%"
      minH="100"
      bg="white"
      shadow="md"
      borderRadius="lg"
      color="blackAlpha.800"
      className="bunset-words"
    >
      <Text
        fontSize="2xl"
        fontFamily="heisei-maru-gothic-std, sans-serif"
        dangerouslySetInnerHTML={{ __html: furigana.ReadingHtml }}
      />
      <Tag
        key="jlpt"
        mr={1}
        size="sm"
        fontSize="x-small"
        variant="outline"
        bg={bgFinal}
        colorScheme="purple"
      >
        JLPT - {jlptLevel || "X"}
      </Tag>
      {kanjis.map((x, idx) => (
        <Tag
          key={idx}
          mr={1}
          size="sm"
          fontSize="x-small"
          variant="subtle"
          colorScheme="gray"
        >
          {x}
        </Tag>
      ))}
      <Text textDecoration="underline" fontSize="x-small">
        {notes}
      </Text>
      <Text fontSize="xs" mt={2}>
        {_.chain(meanings)
          .split(",")
          .map((x) => _.capitalize(_.trim(x)))
          .join(", ")
          .value()}
      </Text>
      <Text
        color="blue.600"
        fontSize="x-small"
        as="a"
        href={JishoLink}
        target="__blank"
      >
        Jisho
      </Text>
    </Box>
  );
};

interface BunSetWordsProps {
  buns: Bun[];
}

const BunSetWords = ({ buns }: BunSetWordsProps) => {
  const [tryVocab] = useAtom(tryVocabAtom);
  const [kicVocab] = useAtom(kicVocabAtom);
  const [c6kVocab] = useAtom(c6kVocabAtom);

  const words = _.chain(buns)
    .flatMap(extractWords)
    .uniq()
    .flatMap((x) => [
      _.get(c6kVocab, x, _.get(tryVocab, x, _.get(kicVocab, x))),
    ])
    .flattenDeep()
    .compact()
    .value();

  return (
    <Flex flex="1" overflowY="auto" minH="0" pb={2}>
      <Grid
        w="100%"
        maxW="8xl"
        mx="auto"
        templateColumns="repeat(auto-fit, minmax(300px, 300px))"
        gap={4}
      >
        {_.chain(words)
          .map((x, idx) => <WordCard key={idx} {...x} />)
          .value()}
      </Grid>
    </Flex>
  );
};

export default BunSetWords;
