import { Box, Flex, Heading, Img, Text, Wrap } from "@chakra-ui/react";
import Loading from "components/loading/Loading";
import { RouteProps } from "framework/routing/types";
import _ from "lodash";
import * as React from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import BunSetCard from "./BunSetCard";

interface CommunityProps extends RouteProps {}

const Community = (props: CommunityProps) => {
  const fireStore = useFirestore();

  const bunSetsRef = fireStore
    .collection("bunsets")
    .where("options.public", "==", true);

  const { status, data: bunSets } = useFirestoreCollectionData<any>(
    bunSetsRef,
    {
      idField: "id",
    }
  );

  return (
    <Box>
      <Box as="section" bgGradient="linear(to-t, gray.50, purple.50)">
        <Box bg="gray.800" as="section" h="300px" position="relative">
          <Box py="32" position="relative" zIndex={1}>
            <Box
              maxW={{ base: "xl", md: "7xl" }}
              mx="auto"
              px={{ base: "6", md: "8" }}
              color="white"
            >
              <Box maxW="xl">
                <Heading as="h1" size="3xl" fontWeight="extrabold">
                  Community Decks
                </Heading>
                <Text fontSize={{ md: "xl" }} mt="4" maxW="lg">
                  Language decks, crafted with ❤ by the community
                </Text>
              </Box>
            </Box>
          </Box>
          <Flex
            id="image-wrapper"
            position="absolute"
            insetX="0"
            insetY="0"
            w="full"
            h="full"
            overflow="hidden"
            align="center"
          >
            <Box position="relative" w="full" h="full">
              <Img
                src="https://source.unsplash.com/orQBzc7Dl3U/1600x850"
                alt="Japanese Unsplash"
                w="full"
                h="full"
                objectFit="cover"
                objectPosition="top bottom"
                position="absolute"
              />
              <Box position="absolute" w="full" h="full" bg="blackAlpha.200" />
            </Box>
          </Flex>
        </Box>
        <Box
          maxW={{ base: "xl", md: "8xl" }}
          mx="auto"
          px={{ base: "6", md: "8" }}
        >
          {status === "loading" && (
            <Loading width="100%" mt="200">
              <Text fontWeight="semibold" p="5" textAlign="center">
                Loading all shared decks...
              </Text>
            </Loading>
          )}

          {status === "success" && (
            <Flex p="8" flex="1" overflowY="auto" minH="0">
              <Wrap spacing="20px">
                {_.chain(bunSets)
                  .orderBy(["modified"], ["desc"])
                  .map((x: any, idx) => <BunSetCard key={idx} bunset={x} />)
                  .value()}
              </Wrap>
            </Flex>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Community;
