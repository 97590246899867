import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Token } from "framework/japanese/sentence";
import React from "react";
import EditMarkupForm from "./EditMarkupForm";

interface HookFormProps {
  data: Token;
  isOpen: boolean;
  onClose: () => void;
  onData: (token: Token) => void;
}

const HookForm = (props: HookFormProps) => {
  const { isOpen, onClose, onData, data } = props;

  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={800} fontSize={20}>
          Edit the reading for this Kanji
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <EditMarkupForm
            onData={onData}
            onClose={onClose}
            data={data}
          ></EditMarkupForm>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default HookForm;
