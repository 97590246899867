import Papa from "papaparse";

export async function loadCSV(path: string) {
  const data = await fetchCSV(path);
  return Papa.parse(data, { header: true });
}

export async function fetchCSV(path: string) {
  const response = await fetch(path);
  const reader = response.body?.getReader();
  const result = await reader?.read();
  const decoder = new TextDecoder("utf-8");
  const csv = await decoder.decode(result?.value);
  return csv;
}
