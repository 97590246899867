import {
  Box,
  Button,
  ButtonGroup,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Img,
  Input,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { addBunSet } from "database/actions/bunset";
import history from "framework/routing/history";
import _ from "lodash";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FiPlusCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useFirestore, useUser } from "reactfire";
import * as yup from "yup";

interface BunSetProps {
  name: string;
  description: string;
}

const schema = yup.object().shape({
  name: yup.string().max(48).required("Name is required"),
  description: yup.string().max(200).required("Description is required"),
});

interface AddBunSetProps {}

const AddBunSet = (props: AddBunSetProps) => {
  const { data: user } = useUser();
  const fireStore = useFirestore();
  const toast = useToast();

  const [isSubmitted, setSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<BunSetProps>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: BunSetProps) => {
    setSubmitted(true);
    const bunSetId = await addBunSet(data, fireStore, user);
    history.push(`/bunset/${bunSetId}`);

    toast({
      title: "Sentence Deck Created",
      description: (
        <Text fontSize="sm">
          Your deck <b>{data.name}</b> is ready 🎉, it's time to add some
          sentences.
        </Text>
      ),
      status: "success",
      duration: 6000,
      isClosable: true,
    });
  };

  return (
    <Box
      px={{ base: "4", md: "10" }}
      bgGradient="linear(to-t, gray.50, purple.50)"
      py="16"
    >
      <Box maxW="4xl" mx="auto" background="white" shadow="md" p={10}>
        <Stack
          spacing={{ base: "4", lg: "20" }}
          direction={{ base: "column", lg: "row" }}
        >
          <Center flex="1" shadow="lg" minH="10rem" maxW={{ lg: "xl" }}>
            <Img
              objectFit="cover"
              w="full"
              h="full"
              minH="520px"
              htmlWidth="576px"
              htmlHeight="520px"
              src="https://source.unsplash.com/SBK40fdKbAg/800x600"
              alt="Japanese"
            />
          </Center>
          <Stack spacing="8" minW="md">
            <Stack as="section" spacing="2">
              <Heading size="lg" fontWeight="extrabold">
                Sentence Deck
              </Heading>
              <Text
                fontSize="sm"
                color={useColorModeValue("gray.600", "gray.400")}
              >
                Create a new sentence deck
              </Text>
            </Stack>
            <Stack as="section" spacing="3">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box mb={6}>
                  <FormControl isInvalid={!_.isEmpty(errors.name)}>
                    <FormLabel
                      color="gray.600"
                      fontWeight="extrabold"
                      htmlFor="name"
                    >
                      Name
                    </FormLabel>
                    <Input
                      id="name"
                      fontWeight="semibold"
                      placeholder="Name for this sentence deck"
                      {...register("name")}
                    />
                    <FormErrorMessage>
                      {errors.name && errors.name.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box mb={6}>
                  <FormControl isInvalid={!_.isEmpty(errors.description)}>
                    <FormLabel
                      color="gray.600"
                      fontWeight="extrabold"
                      htmlFor="description"
                    >
                      Description
                    </FormLabel>
                    <Textarea
                      id="description"
                      fontWeight="semibold"
                      placeholder="Description of this deck"
                      minH={150}
                      {...register("description")}
                    />
                    <FormErrorMessage>
                      {errors.description && errors.description.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>

                <ButtonGroup spacing={5}>
                  <Button
                    mt={4}
                    colorScheme="purple"
                    isDisabled={isSubmitted}
                    isLoading={isSubmitting}
                    type="submit"
                    leftIcon={
                      <Box
                        as={FiPlusCircle}
                        fontSize="sm"
                        mb={-1}
                        transition="transform 0.2s"
                        _groupHover={{ transform: "scale(1.3)" }}
                      />
                    }
                  >
                    Sentence Deck
                  </Button>
                  <Link to="/bunsets">
                    <Button mt={4}>Cancel</Button>
                  </Link>
                </ButtonGroup>
              </form>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default AddBunSet;
