import { hasKanji } from "framework/japanese/kanji";
import { Token } from "framework/japanese/sentence";
import { fit } from "furigana";
import { Furigana } from "gem-furigana";
import _ from "lodash";
import { isKana, toHiragana } from "wanakana";

export const hasFurigana = (token: Token) => {
  const hasReading = !_.isUndefined(token.reading);
  const hasKana = isKana(token.reading);
  return hasReading && hasKana && hasKanji(token.surface);
};

export const getFurigana = (token: Token) => {
  return new Furigana(fit(token.surface, toHiragana(token.reading)));
};
