import {
  Box,
  Button,
  ButtonGroup,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Img,
  Input,
  Stack,
  Text,
  Textarea,
  useColorModeValue as mode,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "components/loading/Loading";
import { updateBunSet } from "database/actions/bunset";
import history from "framework/routing/history";
import { RouteProps } from "framework/routing/types";
import _ from "lodash";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FiSave } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useFirestore, useFirestoreDocData } from "reactfire";
import * as yup from "yup";

interface BunSetProps {
  name: string;
  description: string;
}

const schema = yup.object().shape({
  name: yup.string().max(48).required("Name is required"),
  description: yup.string().max(200).required("Description is required"),
});

interface EditBunSetProps extends RouteProps {}

const EditBunSet = (props: EditBunSetProps) => {
  const bunSetId = props.match.params.id;

  const fireStore = useFirestore();

  const bunSetRef = fireStore.collection("bunsets").doc(bunSetId);
  const { status: bunSetStatus, data: bunSet } = useFirestoreDocData<any>(
    bunSetRef,
    { idField: "id" }
  );

  const toast = useToast();

  const [isSubmitted, setSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<BunSetProps>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: BunSetProps) => {
    setSubmitted(true);
    await updateBunSet(data, bunSet.id, fireStore);
    history.push(`/bunset/${bunSet.id}`);

    toast({
      title: "Sentence Deck updated",
      description: <Text fontSize="sm">Deck updated 🎉</Text>,
      status: "success",
      duration: 6000,
      isClosable: true,
    });
  };

  if (bunSetStatus === "loading") {
    return (
      <Loading width="100%" mt="200">
        <Text fontWeight="semibold" p="5" textAlign="center">
          Loading your decks...
        </Text>
      </Loading>
    );
  } else {
    return (
      <Box
        px={{ base: "4", md: "10" }}
        bgGradient="linear(to-t, gray.50, purple.50)"
        py="16"
      >
        <Box maxW="4xl" mx="auto" background="white" shadow="md" p={10}>
          <Stack
            spacing={{ base: "4", lg: "20" }}
            direction={{ base: "column", lg: "row" }}
          >
            <Center flex="1" shadow="lg" minH="10rem" maxW={{ lg: "xl" }}>
              <Img
                objectFit="cover"
                w="full"
                h="full"
                minH="520px"
                htmlWidth="576px"
                htmlHeight="520px"
                src="https://source.unsplash.com/SBK40fdKbAg/800x600"
                alt="Japanese"
              />
            </Center>
            <Stack spacing="8" minW="md">
              <Stack as="section" spacing="2">
                <Heading size="lg" fontWeight="extrabold">
                  Sentence Deck
                </Heading>
                <Text fontSize="sm" color={mode("gray.600", "gray.400")}>
                  Update sentence deck
                </Text>
              </Stack>
              <Stack as="section" spacing="3">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box mb={6}>
                    <FormControl isInvalid={!_.isEmpty(errors.name)}>
                      <FormLabel
                        color="gray.600"
                        fontWeight="extrabold"
                        htmlFor="name"
                      >
                        Name
                      </FormLabel>
                      <Input
                        id="name"
                        defaultValue={bunSet.name}
                        fontWeight="semibold"
                        placeholder="Name for this sentence deck"
                        {...register("name")}
                      />
                      <FormErrorMessage>
                        {errors.name && errors.name.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                  <Box mb={6}>
                    <FormControl isInvalid={!_.isEmpty(errors.description)}>
                      <FormLabel
                        color="gray.600"
                        fontWeight="extrabold"
                        htmlFor="description"
                      >
                        Description
                      </FormLabel>
                      <Textarea
                        id="description"
                        defaultValue={bunSet.description}
                        fontWeight="semibold"
                        placeholder="Description of this deck"
                        minH={150}
                        {...register("description")}
                      />
                      <FormErrorMessage>
                        {errors.description && errors.description.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Box>

                  <ButtonGroup spacing={5}>
                    <Button
                      mt={4}
                      colorScheme="purple"
                      isDisabled={isSubmitted}
                      isLoading={isSubmitting}
                      type="submit"
                      leftIcon={
                        <Box
                          as={FiSave}
                          fontSize="sm"
                          mb={-1}
                          transition="transform 0.2s"
                          _groupHover={{ transform: "scale(1.3)" }}
                        />
                      }
                    >
                      Sentence Deck
                    </Button>
                    <Link to={`/bunset/${bunSet.id}`}>
                      <Button mt={4}>Cancel</Button>
                    </Link>
                  </ButtonGroup>
                </form>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
    );
  }
};

export default EditBunSet;
