import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { deleteBun } from "database/actions/bun";
import { deleteBunSet, updateBunSetOptions } from "database/actions/bunset";
import { Bun } from "database/model/bun";
import { BunSet } from "database/model/bunset";
import history from "framework/routing/history";
import { useRef } from "react";
import { useFirestore } from "reactfire";

interface BunSetOptionsProps {
  bunSet: BunSet;
  buns: Bun[];
}

const BunSetOptions = (props: BunSetOptionsProps) => {
  const cancelRef = useRef<any>();
  const fireStore = useFirestore();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const deleteDeck = async () => {
    history.push("/bunsets");
    await Promise.all(
      props.buns.map((bun) => deleteBun(bun.id, bun.bunSetId, fireStore))
    );
    await deleteBunSet(props.bunSet.id, fireStore);
    onClose();
  };

  const togglePublic = () => {
    updateBunSetOptions(
      { public: !props.bunSet.options.public },
      props.bunSet.id,
      fireStore
    );
  };

  return (
    <Box maxW="2xl" mx="auto">
      <Stack spacing="12">
        <Stack as="section">
          <Stack spacing="1">
            <Heading size="md" fontWeight="semibold">
              Sharing
            </Heading>
            <Text fontSize="sm">
              Sharing and visibility options for this sentence deck
            </Text>
          </Stack>
          <Box
            bg={useColorModeValue("white", "gray.700")}
            shadow="base"
            rounded="lg"
            p={{ base: "4", md: "8" }}
          >
            <Stack spacing="3">
              <FormControl display="flex" alignItems="center">
                <FormLabel flex="1" fontSize="md" fontWeight="extrabold" mb="0">
                  Make this deck public
                  <Text fontWeight="normal" fontSize="sm">
                    Public decks are readable by everyone on the platform
                  </Text>
                </FormLabel>
                <Switch
                  size="lg"
                  colorScheme="purple"
                  isChecked={props.bunSet.options.public}
                  onChange={togglePublic}
                />
              </FormControl>
            </Stack>
          </Box>
        </Stack>

        <Stack as="section">
          <Stack spacing="1">
            <Heading size="md" fontWeight="semibold">
              Danger Zone
            </Heading>
            <Text fontSize="sm">Irreversible and destructive actions</Text>
          </Stack>
          <Box
            bg={useColorModeValue("white", "gray.700")}
            shadow="base"
            rounded="lg"
            p={{ base: "4", md: "8" }}
          >
            <Text fontWeight="extrabold">Delete deck and associated data</Text>
            <Text fontSize="sm" mt="1" mb="3">
              Once you delete your deck, there is no going back. If this deck is
              shared, your users will lose all access to this deck.
            </Text>
            <Button size="sm" colorScheme="red" onClick={onOpen}>
              Delete Deck
            </Button>
          </Box>
          <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isOpen={isOpen}
            isCentered
          >
            <AlertDialogOverlay />
            <AlertDialogContent>
              <AlertDialogHeader fontWeight="extrabold">
                Delete Deck?
              </AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody fontSize="sm">
                Are you sure you want to discard all of your sentences and
                remove this deck?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button onClick={deleteDeck} colorScheme="red" ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </Stack>
      </Stack>
    </Box>
  );
};

export default BunSetOptions;
