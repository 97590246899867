import { loadCSV } from "framework/csv/loader";
import _ from "lodash";

export const loadKanji = async (filename: string, key: string = "kanji") => {
  const csv = await loadCSV(filename);
  const filteredCSV = _.filter(csv.data, (x: any) => !_.isEmpty(_.get(x, key)));
  return _.keyBy(filteredCSV, key);
};

export const loadVocab = async (
  filename: string,
  key: string = "expression"
) => {
  const csv = await loadCSV(filename);
  const filteredCSV = _.filter(csv.data, (x: any) => !_.isEmpty(_.get(x, key)));
  return _.groupBy(filteredCSV, key);
};
