import splash from "features/splash";
import "firebase/auth";
import "focus-visible/dist/focus-visible";
import history from "framework/routing/history";
import ProtectedRoute from "framework/routing/protected";
import * as React from "react";
import { Route, Router, Switch } from "react-router";

import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";

import Layout from "./Layout";
import "./styles.scss";

const { App: Splash } = splash.components;

const App = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact component={Splash} />
        <ProtectedRoute component={Layout} />
      </Switch>
    </Router>
  );
};

export default App;
