import { Box, BoxProps, CircularProgress, VStack } from "@chakra-ui/react";

const Loading = (props: BoxProps) => {
  return (
    <Box {...props}>
      <VStack>
        <CircularProgress isIndeterminate color="purple.500" size="80px" />
        {props.children}
      </VStack>
    </Box>
  );
};

export default Loading;
