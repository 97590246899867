import ProtectedRoute from "framework/routing/protected";
import React from "react";
import { Redirect, Switch } from "react-router-dom";

import Dashboard from "./Dashboard";
import Layout from "./Layout";

import "./styles.scss";

const App = () => {
  return (
    <Switch>
      <ProtectedRoute exact path="/dashboard" component={Dashboard} />
      <ProtectedRoute path="/dashboard/_" component={Layout} />
      <ProtectedRoute render={() => <Redirect to="/dashboard" />} />
    </Switch>
  );
};

export default App;
