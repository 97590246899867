import { Box, HStack, Text, useColorModeValue as mode } from "@chakra-ui/react";
import SentenceMarkup from "components/markup/sentence/SentenceMarkup";
import { Bun } from "database/model/bun";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import { bunsetDisplayFurigana } from "state/jotai/application/settings";

interface BunCardProps {
  bun: Bun;
  editable: boolean;
}

const BunCard = ({ bun, editable }: BunCardProps) => {
  const [displayFurigana] = useAtom(bunsetDisplayFurigana);

  return (
    <Link to={editable ? `/bunset/${bun.bunSetId}/sentences/${bun.id}` : `#`}>
      <Box
        width="100%"
        p={4}
        mb={2}
        bg={mode("white", "gray.700")}
        shadow={{ md: "base" }}
        rounded={{ md: "xl" }}
        _hover={{
          bgGradient: "linear(to-r, blue.50, purple.50)",
        }}
      >
        <HStack>
          <Box flex="1">
            <SentenceMarkup
              hideFurigana={!displayFurigana}
              sentence={bun.sentence}
              tokens={bun.tokens}
              fontSize="xl"
            />

            <Text fontSize="xs" color="gray.800">
              {bun.translation}
            </Text>
          </Box>
        </HStack>
      </Box>
    </Link>
  );
};

export default BunCard;
