import { BunSetOptions } from "database/model/bunset";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import _ from "lodash";

export interface AddBunSetProps {
  name: string;
  description: string;
}

export const addBunSet = async (
  data: AddBunSetProps,
  fireStore: firebase.firestore.Firestore,
  user: firebase.User
) => {
  const ref = fireStore.collection("bunsets").doc();
  await ref.set({
    id: ref.id,
    ...data,
    notes: {},
    roles: {
      [user.uid]: "owner",
    },
    options: {
      public: false,
    },
    created: firebase.firestore.FieldValue.serverTimestamp(),
    modified: firebase.firestore.FieldValue.serverTimestamp(),
  });

  return ref.id;
};

export const updateBunSet = async (
  data: { name?: string; description?: string; notes?: object },
  bunSetId: string,
  fireStore: firebase.firestore.Firestore
) => {
  const ref = fireStore.collection("bunsets").doc(bunSetId);

  await ref.update(
    _.assign(data, {
      modified: firebase.firestore.FieldValue.serverTimestamp(),
    })
  );
};

export const updateBunSetOptions = async (
  options: BunSetOptions,
  bunSetId: string,
  fireStore: firebase.firestore.Firestore
) => {
  const ref = fireStore.collection("bunsets").doc(bunSetId);
  await ref.update({
    options: options,
  });
};

export const deleteBunSet = async (
  id: string,
  fireStore: firebase.firestore.Firestore
) => {
  const ref = fireStore.collection("bunsets").doc(id);
  await ref.delete();
};
