import ProtectedRoute from "framework/routing/protected";
import React from "react";
import { Redirect, Switch } from "react-router-dom";
import AddBunSet from "./AddBunSet";
import BunSets from "./BunSets";

import "./styles.scss";

const App = () => {
  return (
    <Switch>
      <ProtectedRoute exact path="/bunsets/add" component={AddBunSet} />
      <ProtectedRoute path="/bunsets" component={BunSets} />
      <ProtectedRoute render={() => <Redirect to="/dashboard" />} />
    </Switch>
  );
};

export default App;
