import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Spacer,
  Switch,
} from "@chakra-ui/react";
import { Bun } from "database/model/bun";
import { useAtom } from "jotai";
import _ from "lodash";
import { bunsetDisplayFurigana } from "state/jotai/application/settings";
import BunCard from "./BunCard";

interface BunsProps {
  buns: Bun[];
  editable: boolean;
}

const Buns = ({ buns, editable }: BunsProps) => {
  const [displayFurigana, setFuriganaVisibility] = useAtom(
    bunsetDisplayFurigana
  );

  return (
    <Box>
      <HStack mb={6}>
        <Spacer />
        <Box>
          <FormControl display="flex" alignItems="center">
            <Switch
              id="display-furigana"
              defaultChecked={displayFurigana}
              checked={displayFurigana}
              onChange={() => setFuriganaVisibility(!displayFurigana)}
            />
            <FormLabel htmlFor="display-furigana" mb="1" ml="3">
              Furigana
            </FormLabel>
          </FormControl>
        </Box>
      </HStack>
      <Box>
        {_.chain(buns)
          .orderBy(["created"], ["desc"])
          .map((x: any, idx) => (
            <BunCard key={idx} bun={x} editable={editable} />
          ))
          .value()}
      </Box>
    </Box>
  );
};

export default Buns;
