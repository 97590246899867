import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Token } from "framework/japanese/sentence";
import { fit } from "furigana";
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { bind, isKatakana, toHiragana } from "wanakana";
import * as yup from "yup";

export interface EditMarkupFormProps {
  data: Token;
  onClose: () => void;
  onData: (token: Token) => void;
}

export default function EditMarkupForm(props: EditMarkupFormProps) {
  const readingField = useRef<any>();

  useEffect(() => {
    readingField.current.focus();
    bind(readingField.current, { IMEMode: "toKatakana" });
  });

  const schema = yup.object().shape({
    reading: yup
      .string()
      .required("Reading is required")
      .test("isKatakana", "Only Katakana is allowed", (value) => {
        return value ? isKatakana(value) : false;
      })
      .test("isKana", "Invalid furigana", (value) => {
        if (!_.isUndefined(value) && isKatakana(value)) {
          return fit(props.data.surface, toHiragana(value)) != null;
        } else {
          return false;
        }
      }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Token>({
    resolver: yupResolver(schema),
  });

  const checkKeyDown = (e: any) => {
    if (e.code === "Enter") {
      e.preventDefault();
    }
  };

  const onSubmit = async (data: Token) => {
    props.onData(_.merge(props.data, data));
    props.onClose();
  };

  const { ref, ...rest } = register("reading");

  return (
    <form onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
      <FormControl isInvalid={!_.isEmpty(errors.reading)}>
        <FormLabel htmlFor="reading">
          Reading for <b>{props.data.surface}</b>
        </FormLabel>
        <Input
          id="reading"
          fontFamily="heisei-maru-gothic-std, sans-serif"
          placeholder="Reading of this Markup"
          defaultValue={props.data.reading}
          {...rest}
          ref={(e) => {
            readingField.current = e;
            ref(e);
          }}
        />
        <FormErrorMessage>
          {errors.reading && errors.reading.message}
        </FormErrorMessage>
      </FormControl>
      <Button
        mt={4}
        size="sm"
        colorScheme="purple"
        isLoading={isSubmitting}
        type="submit"
      >
        Submit
      </Button>
    </form>
  );
}
