import { Flex, HStack, Spacer, useColorModeValue } from "@chakra-ui/react";
import React, { isValidElement, ReactElement } from "react";

export const Template: React.FC = (props) => {
  const children = React.Children.toArray(props.children).filter<ReactElement>(
    isValidElement
  );

  return (
    <Flex
      py={4}
      px={{ base: 4, md: 6, lg: 8 }}
      bg={useColorModeValue("white", "gray.800")}
      boxShadow={useColorModeValue("md", "none")}
      borderBottomWidth={useColorModeValue("none", "1px")}
    >
      {children.find((child) => child.type === Brand)?.props.children}

      <HStack spacing={3} display={{ base: "none", md: "flex" }}>
        {children.find((child) => child.type === Links)?.props.children}
      </HStack>
      <Spacer />
      <HStack
        spacing={3}
        mr={5}
        display={{ base: "none", md: "flex" }}
        alignContent="flex-end"
      >
        {children.find((child) => child.type === Buttons)?.props.children}
      </HStack>

      <HStack display={{ base: "none", md: "flex" }} spacing={3}>
        {children.find((child) => child.type === UserProfile)?.props.children}
      </HStack>
    </Flex>
  );
};

const Brand: React.FC = () => null;
const Links: React.FC = () => null;
const Buttons: React.FC = () => null;
const UserProfile: React.FC = () => null;

export const Navbar = Object.assign(Template, {
  Brand,
  Links,
  Buttons,
  UserProfile,
});
