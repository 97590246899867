import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Icon,
  Img,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import Loading from "components/loading/Loading";
import history from "framework/routing/history";
import { RouteProps } from "framework/routing/types";
import _ from "lodash";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  FaChevronRight,
  FaEdit,
  FaLanguage,
  FaPlusCircle,
} from "react-icons/fa";
import { FiLayers, FiSettings } from "react-icons/fi";
import { GrLanguage, GrNotes } from "react-icons/gr";
import { Link } from "react-router-dom";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
  useUser,
} from "reactfire";
import BunNotes from "./BunNotes";
import Buns from "./Buns";
import BunSetKanji from "./BunSetKanji";
import BunSetOptions from "./BunSetOptions";
import BunSetWords from "./BunSetWords";

interface BunSetProps extends RouteProps {}

const BunSet = (props: BunSetProps) => {
  const bunSetId = props.match.params.id;
  const bunSetTab = props.match.params.tab || "sentences";

  const { data: user } = useUser();
  const fireStore = useFirestore();
  const bunSetRef = fireStore.collection("bunsets").doc(bunSetId);
  const bunsRef = fireStore
    .collection("bunsets")
    .doc(bunSetId)
    .collection("buns");

  const { status: bunSetStatus, data: bunSet } = useFirestoreDocData<any>(
    bunSetRef,
    { idField: "id" }
  );

  const { status: bunsStatus, data: buns } = useFirestoreCollectionData<any>(
    bunsRef,
    { idField: "id" }
  );

  // Ownership
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    if (bunSet && _.get(bunSet.roles, [user.uid]) === "owner") {
      setEditable(true);
    }
  }, [bunSet, user.uid]);

  // Tab management
  const [tabIndex, setTabIndex] = React.useState(0);
  const tabs = useMemo(
    () => ["sentences", "notes", "kanji", "words", "options"],
    []
  );

  const handleTabChange = useCallback(
    (index: number) => {
      const tabName = _.get(tabs, index, "sentences");
      history.push(`/bunset/${bunSetId}/${tabName}`);
    },
    [tabs, bunSetId]
  );

  useEffect(() => {
    const tabIndex = _.indexOf(tabs, bunSetTab);
    const newTabIndex = tabIndex >= 0 ? tabIndex : 0;
    setTabIndex(newTabIndex);
    handleTabChange(newTabIndex);
  }, [tabs, bunSetTab, handleTabChange]);

  if (bunSetStatus === "loading" && bunsStatus === "loading") {
    return (
      <Loading width="100%" mt="200">
        <Text fontWeight="semibold" p="5" textAlign="center">
          Loading your decks...
        </Text>
      </Loading>
    );
  } else {
    return (
      <Box>
        <Box as="section" bgGradient="linear(to-t, gray.50, purple.50)" py="10">
          <Box
            maxW={{ base: "xl", md: "8xl" }}
            mx="auto"
            px={{ base: "6", md: "8" }}
          >
            <Stack
              spacing={{ base: "4", lg: "20" }}
              direction={{ base: "column", lg: "row" }}
            >
              <Center flex="1" shadow="lg" minH="10rem" maxW={{ lg: "xl" }}>
                <Img
                  objectFit="cover"
                  w="full"
                  h="full"
                  htmlWidth="576px"
                  htmlHeight="420px"
                  src="https://source.unsplash.com/vJqSAasmCEY/1600x900"
                  alt="Japanese"
                />
              </Center>
              <Box w={{ lg: "xl" }}>
                <Breadcrumb
                  spacing="8px"
                  fontSize="sm"
                  fontWeight="semibold"
                  pb={2}
                  separator={<FaChevronRight color="purple.500" />}
                >
                  <BreadcrumbItem textDecoration="underline">
                    <Link to={`/bunsets`}>Sentence Decks</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink>{bunSet?.name}</BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
                <Heading
                  size="xl"
                  fontWeight="extrabold"
                  letterSpacing="tight"
                  lineHeight="normal"
                >
                  {bunSet?.name || ""}
                </Heading>
                <Text fontSize="md" mt="4" color={mode("gray.600", "gray.400")}>
                  {bunSet?.description || ""}
                </Text>
                {editable && (
                  <Link to={`/bunset/${bunSetId}/sentences/add`}>
                    <Button
                      className="group"
                      mt="8"
                      colorScheme="purple"
                      size="lg"
                      px="8"
                      fontWeight="bold"
                      h="14"
                      iconSpacing="3"
                      variant="outline"
                      leftIcon={
                        <Box
                          as={FaPlusCircle}
                          fontSize="sm"
                          mb={-1}
                          transition="transform 0.2s"
                          _groupHover={{ transform: "scale(1.3)" }}
                        />
                      }
                    >
                      Sentence
                    </Button>
                  </Link>
                )}
                {editable && (
                  <Link to={`/bunset/${bunSetId}/edit`}>
                    <Button
                      className="group"
                      mt="8"
                      ml="5"
                      colorScheme="purple"
                      size="lg"
                      px="8"
                      fontWeight="bold"
                      h="14"
                      iconSpacing="3"
                      variant="outline"
                      leftIcon={
                        <Box
                          as={FaEdit}
                          fontSize="sm"
                          mb={-1}
                          transition="transform 0.2s"
                          _groupHover={{ transform: "scale(1.3)" }}
                        />
                      }
                    >
                      Information
                    </Button>
                  </Link>
                )}
              </Box>
            </Stack>
            <Tabs
              isFitted
              isLazy
              index={tabIndex}
              onChange={handleTabChange}
              variant="enclosed"
              colorScheme="purple"
            >
              <Flex direction="column" align="stretch">
                <Box px="8" pt="8">
                  <Box maxW="8xl" mx="auto">
                    <Flex justify="space-between" align="center">
                      <TabList
                        border="0"
                        position="relative"
                        zIndex={1}
                        w={{ base: "100%", md: "auto" }}
                      >
                        <Tab fontWeight="semibold">
                          <Icon as={FiLayers} mr={3} /> Sentences
                        </Tab>
                        <Tab fontWeight="semibold">
                          <Icon as={GrNotes} mr={3} /> Notes
                        </Tab>
                        <Tab fontWeight="semibold">
                          <Icon as={FaLanguage} mr={3} /> Kanji
                        </Tab>
                        <Tab fontWeight="semibold">
                          <Icon as={GrLanguage} mr={3} /> Words
                        </Tab>
                        {editable && (
                          <Tab fontWeight="semibold">
                            <Icon as={FiSettings} mr={3} /> Options
                          </Tab>
                        )}
                      </TabList>
                    </Flex>
                  </Box>
                </Box>
                <Box pos="relative" zIndex={0}>
                  <Divider
                    borderBottomWidth="2px"
                    opacity={1}
                    borderColor={mode("gray.100", "gray.700")}
                  />
                </Box>
                <Box px="8" flex="1">
                  <Box maxW="8xl" mx="auto">
                    <TabPanels mt="5" h="full">
                      <TabPanel>
                        <Buns buns={buns} editable={editable} />
                      </TabPanel>
                      <TabPanel>
                        <BunNotes
                          bunSet={bunSet}
                          editable={editable}
                        ></BunNotes>
                      </TabPanel>
                      <TabPanel>
                        <BunSetKanji buns={buns} />
                      </TabPanel>
                      <TabPanel>
                        <BunSetWords buns={buns} />
                      </TabPanel>
                      <TabPanel>
                        <BunSetOptions bunSet={bunSet} buns={buns} />
                      </TabPanel>
                    </TabPanels>
                  </Box>
                </Box>
              </Flex>
            </Tabs>
          </Box>
        </Box>
      </Box>
    );
  }
};

export default BunSet;
