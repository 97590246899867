import ProtectedRoute from "framework/routing/protected";
import React from "react";
import { Redirect, Switch } from "react-router-dom";

import Community from "./Community";

import "./styles.scss";

const App = () => {
  return (
    <Switch>
      <ProtectedRoute path="/community" component={Community} />
      <ProtectedRoute render={() => <Redirect to="/dashboard" />} />
    </Switch>
  );
};

export default App;
