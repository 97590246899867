import { Text } from "@chakra-ui/react";
import _ from "lodash";

import { posColor, posKey } from "../constants";
import { hasFurigana } from "./helpers";
import { WithoutRuby, WithRuby } from "./render";
import { GeneralProps } from "./types";

const General = ({
  token,
  editable,
  selectToken,
  idx,
  hideFurigana,
}: GeneralProps) => {
  const color = _.get(posColor, _.get(token.pos, "0"));
  const underline = _.keys(posKey).includes(_.get(token.pos, "0"));

  if (hideFurigana) {
    return (
      <Text as="span" fontFamily="heisei-maru-gothic-std, sans-serif">
        <WithoutRuby token={token} color={color} underline={underline} />
      </Text>
    );
  } else {
    return (
      <Text as="span" fontFamily="heisei-maru-gothic-std, sans-serif">
        {hasFurigana(token) && !editable && (
          <WithRuby token={token} color={color} underline={underline} />
        )}
        {hasFurigana(token) && editable && (
          <WithRuby
            token={token}
            color={color}
            underline={underline}
            cursor="pointer"
            onClick={() => selectToken(idx)}
          />
        )}
        {!hasFurigana(token) && (
          <WithoutRuby token={token} color={color} underline={underline} />
        )}
      </Text>
    );
  }
};

export default General;
