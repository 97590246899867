import {
  Box,
  Divider,
  HStack,
  IconButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import { Editor, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { updateBunSet } from "database/actions/bunset";
import { BunSet } from "database/model/bunset";
import _ from "lodash";
import { Fragment, useEffect } from "react";
import {
  FaAlignCenter,
  FaAlignLeft,
  FaAlignRight,
  FaBold,
  FaGripHorizontal,
  FaHighlighter,
  FaInfoCircle,
  FaItalic,
  FaList,
  FaListOl,
  FaParagraph,
  FaQuoteLeft,
  FaStrikethrough,
  FaUnderline,
} from "react-icons/fa";
import { useFirestore } from "reactfire";

interface MenuBarProps {
  editor: Editor | null;
}

const MenuBar = ({ editor }: MenuBarProps) => {
  if (!editor) {
    return null;
  } else {
    return (
      <VStack pt={4} bgGradient="linear(to-b, whiteAlpha.600, purple.50)">
        <HStack mb={4}>
          <IconButton
            colorScheme="purple"
            aria-label="Paragraph"
            variant="ghost"
            size="sm"
            icon={<FaParagraph />}
            onClick={() => editor.chain().focus().setParagraph().run()}
            isActive={editor.isActive("paragraph")}
          />

          <IconButton
            colorScheme="purple"
            aria-label="Bold"
            variant="ghost"
            size="sm"
            icon={<FaBold />}
            onClick={() => editor.chain().focus().toggleBold().run()}
            isActive={editor.isActive("bold")}
          />

          <IconButton
            colorScheme="purple"
            aria-label="Italic"
            variant="ghost"
            size="sm"
            icon={<FaItalic />}
            onClick={() => editor.chain().focus().toggleItalic().run()}
            isActive={editor.isActive("italic")}
          />

          <IconButton
            colorScheme="purple"
            aria-label="Strike"
            variant="ghost"
            size="sm"
            icon={<FaStrikethrough />}
            onClick={() => editor.chain().focus().toggleStrike().run()}
            isActive={editor.isActive("strike")}
          />

          <IconButton
            colorScheme="purple"
            aria-label="Underline"
            variant="ghost"
            size="sm"
            icon={<FaUnderline />}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            isActive={editor.isActive("underline")}
          />

          <IconButton
            colorScheme="purple"
            aria-label="Highlight"
            variant="ghost"
            size="sm"
            icon={<FaHighlighter />}
            onClick={() => editor.chain().focus().toggleHighlight().run()}
            isActive={editor.isActive("highlight")}
          />

          <IconButton
            colorScheme="purple"
            aria-label="Left Align"
            variant="ghost"
            size="sm"
            icon={<FaAlignLeft />}
            onClick={() => editor.chain().focus().setTextAlign("left").run()}
            isActive={editor.isActive({ textAlign: "left" })}
          />

          <IconButton
            colorScheme="purple"
            aria-label="Center Align"
            variant="ghost"
            size="sm"
            icon={<FaAlignCenter />}
            onClick={() => editor.chain().focus().setTextAlign("center").run()}
            isActive={editor.isActive({ textAlign: "center" })}
          />

          <IconButton
            colorScheme="purple"
            aria-label="Right Align"
            variant="ghost"
            size="sm"
            icon={<FaAlignRight />}
            onClick={() => editor.chain().focus().setTextAlign("right").run()}
            isActive={editor.isActive({ textAlign: "right" })}
          />

          <IconButton
            colorScheme="purple"
            aria-label="Bullet List"
            variant="ghost"
            size="sm"
            icon={<FaList />}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            isActive={editor.isActive("bulletList")}
          />

          <IconButton
            colorScheme="purple"
            aria-label="Ordered List"
            variant="ghost"
            size="sm"
            icon={<FaListOl />}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            isActive={editor.isActive("orderedList")}
          />

          <IconButton
            colorScheme="purple"
            aria-label="Blockquote"
            variant="ghost"
            size="sm"
            icon={<FaQuoteLeft />}
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            isActive={editor.isActive("blockQuote")}
          />

          <IconButton
            colorScheme="purple"
            aria-label="Horizontal Line"
            variant="ghost"
            size="sm"
            icon={<FaGripHorizontal />}
            onClick={() => editor.chain().focus().setHorizontalRule().run()}
          />
        </HStack>
      </VStack>
    );
  }
};

interface BunNotesProps {
  bunSet: BunSet;
  editable: boolean;
}

const BunNotes = (props: BunNotesProps) => {
  const fireStore = useFirestore();

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Highlight,
      Typography,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
    ],
    content: props.bunSet.notes as any,
  });

  // Set the editor as editable depending on the permissions
  useEffect(() => {
    if (editor) {
      editor?.setEditable(props.editable);
    }
  }, [props.editable, editor]);

  // Load from remote source
  useEffect(() => {
    if (editor && !props.editable && !_.isEmpty(props.bunSet?.notes)) {
      editor?.commands.setContent(props.bunSet?.notes as any, false);
    }
  }, [editor, props.editable, props.bunSet]);

  // Save the notes on any changes
  useEffect(() => {
    if (props.editable) {
      editor?.on("blur", () => {
        console.info("Saving notes to firestore database!");
        updateBunSet({ notes: editor.getJSON() }, props.bunSet.id, fireStore);
      });
    }
  }, [editor, props.bunSet.id, fireStore, props.editable]);

  return (
    <Box
      bgGradient="linear(to-b, whiteAlpha.800, purple.50)"
      shadow="md"
      borderRadius="xl"
      fontSize="md"
      fontFamily="'Noto Sans JP', 'M PLUS Rounded 1c', heisei-maru-gothic-std, sans-serif"
    >
      {props.editable && (
        <Fragment>
          <MenuBar editor={editor} />
          <Divider mb={5} />
          <Box px={8} py={4}>
            <EditorContent editor={editor} />
          </Box>
        </Fragment>
      )}

      {!props.editable && editor?.state.doc.textContent.length !== 0 && (
        <Box px={8} py={4}>
          <EditorContent editor={editor} />
        </Box>
      )}

      {!props.editable && editor?.state.doc.textContent.length === 0 && (
        <VStack py={10}>
          <HStack>
            <VStack spacing={5}>
              <FaInfoCircle color="purple" size={24} />
              <Text
                maxW="xl"
                textAlign="center"
                fontSize="xl"
                fontFamily="'Noto Sans JP', 'M PLUS Rounded 1c', heisei-maru-gothic-std, sans-serif"
              >
                {props.bunSet.description}
              </Text>
            </VStack>
          </HStack>
        </VStack>
      )}
    </Box>
  );
};

export default BunNotes;
