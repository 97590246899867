import {
  Box,
  Button,
  ChakraProvider,
  Grid,
  Text,
  theme,
  VStack,
} from "@chakra-ui/react";
import firebase from "firebase/app";
import kanjirocks_logo from "images/logo/kanjirocks.png";
import * as React from "react";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import { AuthCheck, useAuth } from "reactfire";
import "./styles.scss";

const App = () => {
  const auth = useAuth();

  const signIn = async () => {
    await auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  };

  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="x1">
        <Grid minH="100vh" p={3} bgGradient="linear(to-t, blue.50, purple.50)">
          <VStack alignSelf="center" spacing={8} zIndex={1}>
            <img src={kanjirocks_logo} alt="KanjiRocks Logo" width={300} />
            <Text as="span" fontWeight="extrabold" fontSize={50}>
              Kanji Rocks!
            </Text>
            <Text
              as="span"
              fontSize={50}
              fontFamily="heisei-maru-gothic-std, sans-serif"
            >
              日本語を勉強しましょう!
            </Text>
            <AuthCheck
              fallback={
                <Button
                  size="lg"
                  rightIcon={<FcGoogle />}
                  colorScheme="purple"
                  variant="outline"
                  onClick={signIn}
                  fontFamily="heisei-maru-gothic-std, sans-serif"
                >
                  グーグルでログイン
                </Button>
              }
            >
              <Link to="/dashboard">
                <Button
                  size="lg"
                  rightIcon={<FaArrowAltCircleRight />}
                  colorScheme="purple"
                  variant="outline"
                  fontFamily="heisei-maru-gothic-std, sans-serif"
                >
                  始めましょう
                </Button>
              </Link>
            </AuthCheck>
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
};

export default App;
