import { Box, Button, Flex, Heading, Img, Stack, Text } from "@chakra-ui/react";
import * as React from "react";
import { Link } from "react-router-dom";

export const Dashboard = () => {
  return (
    <Box bg="gray.800" as="section" h="calc(100vh - 74px)" position="relative">
      <Box py="32" position="relative" zIndex={1}>
        <Box
          maxW={{ base: "xl", md: "7xl" }}
          mx="auto"
          px={{ base: "6", md: "8" }}
          color="white"
        >
          <Box maxW="xl">
            <Heading as="h1" size="3xl" fontWeight="extrabold">
              Learn Japanese Kanji and Vocabulary in Context
            </Heading>
            <Text fontSize={{ md: "2xl" }} mt="4" maxW="lg">
              Master all of the Joyo Kanji using sentence decks created by you
              and other members of the Kanji Rocks community.
            </Text>
            <Stack
              direction={{ base: "column", md: "row" }}
              mt="10"
              spacing="4"
            >
              <Link to="/bunsets">
                <Button
                  href="#"
                  colorScheme="purple"
                  px="8"
                  rounded="full"
                  size="lg"
                  fontSize="md"
                  fontWeight="bold"
                >
                  Get Started
                </Button>
              </Link>
            </Stack>
          </Box>
        </Box>
      </Box>
      <Flex
        id="image-wrapper"
        position="absolute"
        insetX="0"
        insetY="0"
        w="full"
        h="full"
        overflow="hidden"
        align="center"
      >
        <Box position="relative" w="full" h="full">
          <Img
            src="https://source.unsplash.com/wPMvPMD9KBI/800x1200"
            alt="Japanese Unsplash"
            w="full"
            h="full"
            objectFit="cover"
            objectPosition="top bottom"
            position="absolute"
          />
          <Box position="absolute" w="full" h="full" bg="blackAlpha.200" />
        </Box>
      </Flex>
    </Box>
  );
};

export default Dashboard;
