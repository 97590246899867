import {
  Box,
  Divider,
  Flex,
  Heading,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue as mode,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";
import { FaInfoCircle } from "react-icons/fa";
import Profile from "./Profile";

const Layout = () => {
  return (
    <Tabs isFitted isLazy variant="enclosed" colorScheme="purple">
      <Flex direction="column" align="stretch">
        <Box px="8" pt="8" bgGradient="linear(to-t, gray.50, purple.50)">
          <Box maxW="8xl" mx="auto">
            <Flex
              direction={{ base: "column", md: "row" }}
              justify="space-between"
              align="flex-start"
              mb="10"
            >
              <VStack
                mb={{ base: "4", md: "0" }}
                textAlign="left"
                alignItems="flex-start"
              >
                <Heading
                  as="h2"
                  size="lg"
                  fontWeight="700"
                  letterSpacing="tight"
                  fontFamily="heisei-maru-gothic-std, sans-serif"
                >
                  ダッシュボード
                </Heading>
                <Text
                  mt="4"
                  color={mode("gray.500", "gray.300")}
                  fontSize="sm"
                  fontWeight="semibold"
                >
                  Kanji Rocks Dashboard
                </Text>
                <Text color={mode("gray.600", "gray.400")} fontSize="xs">
                  Monitor your progress on the road to mastering all the Jōyō
                  Kanji
                </Text>
              </VStack>
            </Flex>
            <Flex justify="space-between" align="center">
              <TabList
                border="0"
                position="relative"
                zIndex={1}
                w={{ base: "100%", md: "auto" }}
              >
                <Tab fontWeight="semibold">
                  <Icon as={FaInfoCircle} mr={3} /> My Profile
                </Tab>
              </TabList>
            </Flex>
          </Box>
        </Box>
        <Box pos="relative" zIndex={0}>
          <Divider
            borderBottomWidth="2px"
            opacity={1}
            borderColor={mode("gray.100", "gray.700")}
          />
        </Box>
        <Box px="8" flex="1">
          <Box maxW="8xl" mx="auto">
            <TabPanels mt="5" h="full">
              <TabPanel>
                <Profile />
              </TabPanel>
            </TabPanels>
          </Box>
        </Box>
      </Flex>
    </Tabs>
  );
};

export default Layout;
