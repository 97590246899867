import {
  Box,
  Center,
  ChakraProvider,
  extendTheme,
  Icon,
  Progress,
  Text,
  useColorModeValue as mode,
  VStack,
} from "@chakra-ui/react";
import { PageViewLogger } from "components/analytics/PageViewLogger";
import bunset from "features/bunset";
import bunsets from "features/bunsets";
import community from "features/community";
import dashboard from "features/dashboard";
import "firebase/analytics";
import "firebase/auth";
import "firebase/storage";
import history from "framework/routing/history";
import ProtectedRoute from "framework/routing/protected";
import { Location } from "history";
import kanjirocks_logo from "images/logo/kanjirocks-small.png";
import _ from "lodash";
import * as React from "react";
import { useState } from "react";
import { IconType } from "react-icons";
import { AiOutlineContainer } from "react-icons/ai";
import { GoDashboard } from "react-icons/go";
import { HiOutlineShare } from "react-icons/hi";
import { Link, Redirect, Router, Switch } from "react-router-dom";
import { useAuth, useUser } from "reactfire";
import { isJapanese } from "wanakana";
import Data from "./Data";
import { Navbar } from "./Navbar";
import { NavLink } from "./NavLink";
import "./styles.scss";
import { UserProfile } from "./UserProfile";

const { App: Dashboard } = dashboard.components;
const { App: BunSets } = bunsets.components;
const { App: BunSet } = bunset.components;
const { App: Community } = community.components;

type NavLinkSpecification = {
  key: string;
  text: string;
  icon: IconType;
  link: string;
};

const navLinks: NavLinkSpecification[] = [
  {
    key: "dashboard",
    text: "Dashboard",
    icon: GoDashboard,
    link: "/dashboard",
  },
  {
    key: "bunsets",
    text: "文",
    icon: AiOutlineContainer,
    link: "/bunsets",
  },
  {
    key: "community",
    text: "Community",
    icon: HiOutlineShare,
    link: "/community",
  },
];

const generateNavLinks = (
  links: NavLinkSpecification[],
  location: Location
) => {
  const feature = _.chain(location.pathname)
    .split("/")
    .compact()
    .first()
    .value();

  return (
    <>
      {links.map(function (link) {
        const active = [feature, `${feature}s`].includes(link.key);
        const fontFamily = isJapanese(link.text)
          ? "heisei-maru-gothic-std, sans-serif"
          : "";

        const fontSize = isJapanese(link.text) ? "md" : "sm";
        return (
          <NavLink isActive={active} key={link.key} to={link.link}>
            <Icon as={link.icon} marginTop={-1} />
            <Text as="span" fontFamily={fontFamily} fontSize={fontSize} ml={2}>
              {link.text}
            </Text>
          </NavLink>
        );
      })}
    </>
  );
};

const Layout = () => {
  const auth = useAuth();
  const { data: user } = useUser();
  const [loading] = useState(false);

  // Github Issue
  // https://github.com/FirebaseExtended/reactfire/discussions/228#discussioncomment-182830
  const clearFirestoreCache = () => {
    const map = (globalThis as any)["_reactFirePreloadedObservables"];
    Array.from(map.keys()).forEach(
      (key: any) => key.includes("firestore") && map.delete(key)
    );
  };

  const signOut = async () => {
    await auth.signOut();
    history.push("/");
    clearFirestoreCache();
  };

  const theme = extendTheme({
    styles: {
      global: (props) => ({
        body: {
          background: mode("gray.50", "gray.700"),
        },
      }),
    },
    components: {
      Input: { defaultProps: { focusBorderColor: "purple.500" } },
      Link: { baseStyle: { _focus: { boxShadow: "none" } } },
      Button: { baseStyle: { _focus: { boxShadow: "none" } } },
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <Box height="100vh" minH="24rem">
        <Data />
        <Box bgColor="white">
          <Navbar>
            <Navbar.Brand>
              <Center marginEnd="10">
                <img src={kanjirocks_logo} alt="KanjiRocks Logo" width={35} />
                <Link to="/">
                  <VStack spacing={0} ml={5} mb={-2}>
                    <Text
                      fontSize={25}
                      mb={-3}
                      color="purple.800"
                      fontFamily="heisei-maru-gothic-std, sans-serif"
                    >
                      漢字
                    </Text>
                    <Text
                      as="span"
                      fontSize="sm"
                      fontWeight="extrabold"
                      color="purple.600"
                    >
                      Rocks
                    </Text>
                  </VStack>
                </Link>
              </Center>
            </Navbar.Brand>
            <Navbar.Links>
              {generateNavLinks(navLinks, history.location)}
            </Navbar.Links>
            <Navbar.UserProfile>
              <UserProfile user={user} signOut={signOut} />
            </Navbar.UserProfile>
          </Navbar>
        </Box>
        <Progress
          mt={0.5}
          size="xs"
          h={0.5}
          bg="transparent"
          bgGradient="linear(to-b, gray.50, purple.50)"
          colorScheme="purple"
          isIndeterminate={loading}
        />
        <Router history={history}>
          <Switch>
            <ProtectedRoute path="/dashboard" component={Dashboard} />
            <ProtectedRoute path="/bunsets" component={BunSets} />
            <ProtectedRoute path="/bunset" component={BunSet} />
            <ProtectedRoute path="/community" component={Community} />
            <ProtectedRoute render={() => <Redirect to="/dashboard" />} />
          </Switch>
          <PageViewLogger location={history.location} />
        </Router>
      </Box>
    </ChakraProvider>
  );
};

export default Layout;
